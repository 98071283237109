//swiper
var swiper = new Swiper('.sw1', {
    slidesPerView: 2,
    slidesPerGroup: 1,
    loop: true,
    loopFillGroupWithBlank: true,
    pagination: {
        el: '.swiper-pagination',
        dynamicBullets: true,
    },
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
    breakpoints: {
        // when window width is >= 320px
        992: {
          slidesPerView: 1,
        },
    }
});
var swiper = new Swiper('.sw2', {
    loop: true,
    loopFillGroupWithBlank: true,
    pagination: {
        el: '.swiper-pagination',
        dynamicBullets: true,
    },
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
});
var swiper = new Swiper('.sw3', {
    slidesPerView: 4,
    slidesPerGroup: 1,
    loop: true,
    loopFillGroupWithBlank: true,
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
    breakpoints: {
        992: {
          slidesPerView: 3,
        },
        750: {
          slidesPerView: 2,
        },
        450: {
          slidesPerView: 1,
        },
    }
});


function addTextToInput(anElement) {
    var text = document.getElementById('hidden-input').value;
    text = anElement.value;
    document.getElementById('hidden-input').value = text;
}
//scroll
const anchors = document.querySelectorAll('a[href*="#"]')

for (let anchor of anchors) {
  anchor.addEventListener('click', function (e) {
    e.preventDefault()
    
    const blockID = anchor.getAttribute('data-href').substr(1)
    
    document.getElementById(blockID).scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    })
  })
}

//audio
function playAudio(el, s){
    event.preventDefault();
    var myaudio = document.getElementById("myaudio" + s);
    if(myaudio.paused == true)
    {
        document.getElementById("myaudio" + s).play();
        el.style.textDecoration = 'underline';
        el.style.textDecorationColor = '#5266B2';
    }
    else if (myaudio.paused == false)
    {
        document.getElementById("myaudio" + s).pause();
        el.style.textDecoration = 'none';
    }
}


//animate
var arTop, arW, arH, scrl, scrlHeight, mouseY, mouseX, st;
var i = 0,
    z = 1,
    srav = 0,
    w, h, t, j;

var mass = document.getElementsByClassName('block-animate');
document.addEventListener('DOMContentLoaded', go);
window.addEventListener('resize', go);
window.onload = function () {

setInterval("btnAnimate()", 50);
}

window.addEventListener('scroll', positY);
document.getElementById('text-cont1').addEventListener("mousemove", onMouseMove); 

function btnAnimate() {
    if (i == 28) {
       z = -1;
    } 
    else if (i == 0){
        z = 1;
    }

    // w = w + (0.5 * z);
    // h = h + (0.5 * z);
    // t = t - (0.25 * z);
 
    arTop = arTop + (0.25 * z);
    arW = arW + (0.4 * z);
    arH = arH + (0.4 * z);

    st = st - (0.9 * z);

    // document.getElementById('arrow-link').style.top = arTop + "px";
    // document.getElementById('arr-bl').style.width = arW + "px";
    // document.getElementById('arr-bl').style.height = arH + "px";
    // document.getElementById('arr-bl').style.top = arTop + "px";
    document.getElementById('st-block').style.top = st + "px";
    // document.getElementById('arr-bl').style.right = arTop + "px";  

    // elem.style.width = w + "px";
    // for (j = 0; j < elem.length; j++) {
    //     elem[j].style.width = w + "px";
    //     elem[j].style.height = h + "px";
    //     elem[j].style.top = t + "px";
    //     elem[j].style.right = t + "px";  
    // }

    i = i + 1 * z;
}
function go() {
    // w = document.getElementById('b-a').offsetWidth;
    // h = document.getElementById('b-a').offsetHeight;
    // t = document.getElementById('b-a').offsetTop;
    // arW= document.getElementById('arrow-link').offsetWidth;
    // arH = document.getElementById('arrow-link').offsetHeight;
    // arTop = document.getElementById('arr-bl1').offsetTop;
    st = 0;
    // arTop = arTop + 10;
    i = 0;
    z = 1;
};
function positY() {
    scrl = pageYOffset;
    scrlHeight = document.getElementById('b-white').offsetHeight;
    if (scrl > srav) {
        document.getElementById('b-white').style.opacity = "0";
        document.getElementById('header').style.top = -scrlHeight + "px";
        srav = scrl;
    }
    else {
        document.getElementById('b-white').style.opacity = "1";
        document.getElementById('header').style.top = 0;
        srav = scrl;
    }
}
function onMouseMove() { 
    mouseY = event.pageY - event.offsetHeight;
    mouseX = event.pageX;
}



//Paralax
const paralaxCon = document.getElementById('paralax');
const paralaxCon1 = document.getElementById('paralax1');
const paralaxCon2 = document.getElementById('paralax2');
const paralaxCon3 = document.getElementById('paralax3');
const paralaxCon4 = document.getElementById('paralax4');
const paralaxCon5 = document.getElementById('paralax5');
const paralaxCon6 = document.getElementById('paralax6');
const paralaxCon7 = document.getElementById('paralax7');
const paralaxCon8 = document.getElementById('paralax8');
const paralaxCon9 = document.getElementById('paralax9');
const paralaxCon10 = document.getElementById('paralax10');
const paralaxCon11 = document.getElementById('paralax11');
paralax(paralaxCon);
// paralax(paralaxCon1);
paralax(paralaxCon2);
paralax(paralaxCon3);
paralax(paralaxCon4);
paralax(paralaxCon5);
paralax(paralaxCon6);
paralax(paralaxCon7);
paralax(paralaxCon8);
paralax(paralaxCon9);
paralax(paralaxCon10);
paralax(paralaxCon11);

function paralax(paralaxContainer) {
  const layers = paralaxContainer.children;

  const moveLayers = e =>{
    const initialX = (window.innerWidth / 2) - e.pageX;
    const initialY = (window.innerHeight / 2) - e.pageY;

    [].slice.call(layers).forEach((layer, i) => {
      const divider = (i + 2) / 500;

      const positionX = initialX * divider;
      const positionY = initialY * divider;
      const image = layer.firstElementChild;
      const bottomPosition = (window.innerHeight / 2) * divider;

      var transformString = 'translate(' + positionX + 'px, ' + positionY + 'px)';
              
      layer.style.transform = transformString;
      image.style.bottom = '-' + bottomPosition - 35 + 'px';
    })
  };

  window.addEventListener('mousemove', moveLayers);
}